<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17 5.5C17 6.32843 16.3284 7 15.5 7C14.6716 7 14 6.32843 14 5.5C14 4.67157 14.6716 4 15.5 4C16.3284 4 17 4.67157 17 5.5ZM17.95 6H20C20.2761 6 20.5 5.77614 20.5 5.5C20.5 5.22386 20.2761 5 20 5H17.95C17.7184 3.85888 16.7095 3 15.5 3C14.2905 3 13.2816 3.85888 13.05 5H4C3.72386 5 3.5 5.22386 3.5 5.5C3.5 5.77614 3.72386 6 4 6H13.05C13.2816 7.14112 14.2905 8 15.5 8C16.7095 8 17.7184 7.14112 17.95 6ZM3.5 11.5C3.5 11.2239 3.72386 11 4 11H5.05001C5.28164 9.85888 6.29052 9 7.5 9C8.70948 9 9.71836 9.85888 9.94999 11H20C20.2761 11 20.5 11.2239 20.5 11.5C20.5 11.7761 20.2761 12 20 12H9.94999C9.71836 13.1411 8.70948 14 7.5 14C6.29052 14 5.28164 13.1411 5.05001 12H4C3.72386 12 3.5 11.7761 3.5 11.5ZM7.5 10C8.32843 10 9 10.6716 9 11.5C9 12.3284 8.32843 13 7.5 13C6.67157 13 6 12.3284 6 11.5C6 10.6716 6.67157 10 7.5 10ZM4 17C3.72386 17 3.5 17.2239 3.5 17.5C3.5 17.7761 3.72386 18 4 18H10.05C10.2816 19.1411 11.2905 20 12.5 20C13.7095 20 14.7184 19.1411 14.95 18H20C20.2761 18 20.5 17.7761 20.5 17.5C20.5 17.2239 20.2761 17 20 17H14.95C14.7184 15.8589 13.7095 15 12.5 15C11.2905 15 10.2816 15.8589 10.05 17H4ZM14 17.5C14 16.6716 13.3284 16 12.5 16C11.6716 16 11 16.6716 11 17.5C11 18.3284 11.6716 19 12.5 19C13.3284 19 14 18.3284 14 17.5Z"
			fill="white"
		/>
	</svg>
</template>
