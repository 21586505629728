<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
	>
		<path
			d="M6.91667 13.5834C6.68655 13.5834 6.5 13.77 6.5 14C6.5 14.2301 6.68655 14.4167 6.91667 14.4167H11.0833C11.3134 14.4167 11.5 14.2301 11.5 14C11.5 13.77 11.3134 13.5834 11.0833 13.5834H6.91667Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.8067 1.14598C9.74702 0.309413 8.25168 0.309413 7.19205 1.14598L1.77538 5.4223C1.07477 5.97541 0.666016 6.8189 0.666016 7.71153V14.4167C0.666016 16.0275 1.97185 17.3334 3.58268 17.3334H14.416C16.0268 17.3334 17.3327 16.0275 17.3327 14.4167V7.71153C17.3327 6.8189 16.9239 5.97541 16.2233 5.4223L10.8067 1.14598ZM7.70843 1.80005C8.46535 1.2025 9.53335 1.2025 10.2903 1.80005L15.7069 6.07636C16.2074 6.47145 16.4993 7.07394 16.4993 7.71153V14.4167C16.4993 15.5673 15.5666 16.5 14.416 16.5H3.58268C2.43209 16.5 1.49935 15.5673 1.49935 14.4167V7.71153C1.49935 7.07394 1.79132 6.47145 2.29176 6.07636L7.70843 1.80005Z"
			fill="white"
		/>
	</svg>
</template>
