<template>
	<div class="broker-search-panel">
		<div class="broker-search-panel__container">
			<div class="broker-search-panel__body">
				<or-drop-down>
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="$t('general.price')"
							icon-before="price"
							icon-after="arrow"
							class="broker-search-panel__input main-type"
							v-model="displayPrice"
							:class="{ 'active-input': active, 'have-content': displayPrice }"
						/>
					</template>
					<template #content>
						<PriceBlock
							class="broker-search-panel__drop-content"
							v-model="price"
							:range="priceRange"
						/>
					</template>
				</or-drop-down>

				<or-drop-down>
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="$t('general.square')"
							:prefix="currentSquareSymbol"
							icon-after="arrow"
							icon-before=""
							v-model="displayArea"
							class="broker-search-panel__input main-type"
							:class="{ 'active-input': active, 'have-content': displayArea }"
						/>
					</template>
					<template #content>
						<Square
							class="broker-search-panel__drop-content"
							v-model="area"
							:range="areaRange"
						/>
					</template>
				</or-drop-down>

				<or-drop-down>
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="$t('general.propertyType')"
							v-model="displayTypes"
							icon-before="property"
							icon-after="arrow"
							class="broker-search-panel__input main-type placeholder-wrap"
							:class="{
								'active-textarea': active,
								'have-content': displayTypes.length
							}"
						/>
					</template>
					<template #content>
						<PropertyList
							class="broker-search-panel__drop-content"
							:is-hidden-on-click="false"
							:values="filters.types"
							v-model="types"
						/>
					</template>
				</or-drop-down>

				<or-drop-down>
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="$t('general.rooms')"
							icon-before="rooms"
							icon-after="arrow"
							class="broker-search-panel__input main-type placeholder-wrap"
							v-model="displayRooms"
							:class="{
								'active-textarea': active || displayRooms,
								'have-content': displayRooms
							}"
						/>
					</template>
					<template #content>
						<CountRoomsBaths
							class="broker-search-panel__drop-content"
							:is-show-bath="false"
							:availableBedrooms="filters.bedrooms"
							v-model:bedrooms="bedrooms"
						/>
					</template>
				</or-drop-down>

				<or-drop-down type="search">
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="$t('web.project')"
							icon-before="project"
							icon-after="arrow"
							class="broker-search-panel__input main-type placeholder-wrap"
							v-model="displayProjects"
							:class="{
								'active-input': active,
								'have-content': displayProjects
							}"
						/>
					</template>
					<template #content>
						<WiseProjectsList
							class="broker-search-panel__drop-content broker-search-panel__drop-content--project"
							:projects="filters.projects"
							v-model="projects"
						/>
					</template>
				</or-drop-down>

				<or-drop-down class="search-panel__item" type="search">
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="'Cities'"
							icon-before="city"
							icon-after="arrow"
							class="search-panel__input main-type"
							v-model="displayCities"
							:class="{ 'active-input': active, 'have-content': displayCities }"
						/>
					</template>
					<template v-slot:content>
						<SearchList
							class="search-panel__drop-content"
							:regions="filters.city"
							v-model="cities"
						/>
					</template>
				</or-drop-down>

				<or-drop-down class="broker-search-panel__area-input" type="search">
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="$t('general.area')"
							icon-before="location"
							icon-after="arrow"
							class="broker-search-panel__input main-type"
							v-model="displayRegions"
							:class="{
								'active-input': active,
								'have-content': displayRegions
							}"
						/>
					</template>
					<template #content>
						<SearchList
							class="broker-search-panel__drop-content"
							:regions="filters.region"
							v-model="regions"
						/>
					</template>
				</or-drop-down>

				<or-drop-down type="search">
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="$t('web.developer')"
							icon-before="developer"
							icon-after="arrow"
							class="broker-search-panel__input main-type placeholder-wrap"
							v-model="displayDevelopers"
							:class="{
								'active-input': active,
								'have-content': displayDevelopers
							}"
						/>
					</template>
					<template #content>
						<DevelopersList
							class="broker-search-panel__drop-content broker-search-panel__drop-content--project"
							:developers="filters.developers"
							v-model="developers"
						/>
					</template>
				</or-drop-down>

				<or-drop-down>
					<template #toggle>
						<or-input
							readonly
							:placeholderWrap="$t('web.post_handover')"
							icon-before="post-handover"
							v-model="postHandoverView"
							icon-after="arrow"
							class="broker-search-panel__input main-type placeholder-wrap"
							:class="{
								'have-content': postHandoverView
							}"
						/>
					</template>
					<template #content>
						<div class="advanced-list">
							<ul class="advanced-list__list furnished-list">
								<li
									v-for="item in config.post_handover"
									:key="item.value"
									class="advanced-list__item"
								>
									<label :class="{ active: item.value === postHandover }">
										{{ $t(item.title) }}
										<input
											type="radio"
											v-model="postHandover"
											:value="item.value"
											hidden
										/>
									</label>
								</li>
							</ul>
						</div>
					</template>
				</or-drop-down>

				<or-drop-down>
					<template v-slot:toggle="{ active }">
						<or-input
							readonly
							:placeholderWrap="$t('web.year_construction')"
							icon-before="calendar"
							icon-after="arrow"
							class="broker-search-panel__input main-type placeholder-wrap"
							v-model="displayYears"
							:class="{ 'active-input': active, 'have-content': displayYears }"
						/>
					</template>
					<template #content>
						<YearsList :is-hidden-on-click="false" v-model="yearConst" />
					</template>
				</or-drop-down>

				<or-drop-down>
					<template #toggle>
						<or-input
							readonly
							:resize="false"
							:placeholderWrap="$t('web.furnished')"
							icon-before="furnishing"
							icon-after="arrow"
							v-model="furnishedView"
							:class="{
								'have-content': furnishedView
							}"
							class="broker-search-panel__input main-type placeholder-wrap"
						/>
					</template>
					<template #content>
						<div class="search-panel__drop-content advanced-list">
							<ul class="advanced-list__list">
								<li
									v-for="item in config.furnished"
									:key="item.value"
									class="advanced-list__item"
								>
									<label :class="{ active: item.value === furnished }">
										{{ $t(item.title) }}
										<input
											type="radio"
											v-model="furnished"
											:value="item.value"
											hidden
										/>
									</label>
								</li>
							</ul>
						</div>
					</template>
				</or-drop-down>

				<or-drop-down>
					<template #toggle>
						<or-input
							readonly
							:resize="false"
							:placeholderWrap="$t('web.parking')"
							icon-before="parking"
							v-model="hasParkingView"
							icon-after="arrow"
							:class="{
								'have-content': hasParkingView
							}"
							class="broker-search-panel__input main-type placeholder-wrap"
						/>
					</template>
					<template #content>
						<div class="advanced-list">
							<ul class="advanced-list__list furnished-list">
								<li
									v-for="item in config.parking"
									:key="item.value"
									class="advanced-list__item"
								>
									<label :class="{ active: item.value === hasParking }">
										{{ $t(item.title) }}
										<input
											type="radio"
											v-model="hasParking"
											:value="item.value"
											hidden
										/>
									</label>
								</li>
							</ul>
						</div>
					</template>
				</or-drop-down>
			</div>

			<div class="broker-search-panel__btns">
				<or-button
					v-if="isShowClearBtn"
					@click="clear"
					:height="48"
					class="secondary"
				>
					{{ $t('general.clear') }}
				</or-button>
				<or-button @click="submit" :height="48">
					{{ $t('general.search') }}
				</or-button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { createRequestBroker, createRequest } from '@/api/requestBuilder'
import requestBrokerConfigs from '@/api/requestBrokerConfigs'
import requestConfigs from '@/api/requestConfigs'
import YearsList from '../../modules/search/components/advancedSearch/components/YearsList.vue'
import SearchList from '@/components/SearchPanel/SearchList.vue'
import DevelopersList from '@/components/SearchPanel/DevelopersList.vue'
import PropertyList from '@/components/SearchPanel/PropertyList.vue'
import CountRoomsBaths from '@/components/SearchPanel/CountRoomsBaths.vue'
import PriceBlock from '@/components/SearchPanel/Price.vue'
import Square from '@/components/SearchPanel/Square.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, reactive, shallowRef, watch } from 'vue'
import { simbol } from '@/enums/moneyСollections'
import { formatCash, formatNumber } from '@/utils/formatMoney'
import { useStore } from 'vuex'
import { squareSymbols } from '@/enums/areaCollections'
import cloneDeep from 'lodash.clonedeep'
import WiseProjectsList from '@/components/SearchPanel/WiseProjectsList.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
	type: {
		type: String,
		default: ''
	},
	isShowClearBtn: {
		type: Boolean,
		default: false
	}
})

const config = {
	furnished: [
		{ title: 'general.any', value: null },
		{ title: 'web.broker_search_furnished_yes', value: 'full' },
		{ title: 'web.broker_search_furnished_no', value: 'none' },
		{ title: 'web.broker_search_furnished_partly', value: 'partly' }
	],
	parking: [
		{ title: 'general.yes', value: '1' },
		{ title: 'general.any', value: null }
	],
	post_handover: [
		{ title: 'general.yes', value: '1' },
		{ title: 'general.any', value: null }
	]
}

const emit = defineEmits(['update'])

const route = props.type === 'page' ? useRoute() : reactive({ query: {} })
const router = useRouter()
const store = useStore()
const { t } = useI18n()

const filters = shallowRef()

let _loadFiltersCall
await loadFilters()

const price = computed({
	get() {
		const from = route.query.price_from
		const to = route.query.price_to
		return { from, to }
	},
	set({ from, to }) {
		mutateQueryState({ price_from: from, price_to: to })
	}
})

const priceRange = computed(() => {
	return {
		from: filters.value.price_from[currencyMapper(store.state.activeMoney)],
		to: filters.value.price_to[currencyMapper(store.state.activeMoney)]
	}
})

const area = computed({
	get() {
		const from = route.query.area_from
		const to = route.query.area_to
		return { from, to }
	},
	set({ from, to }) {
		mutateQueryState({ area_from: from, area_to: to })
	}
})

const areaRange = computed(() => {
	return {
		from: filters.value.area_from[store.state.areaUnit],
		to: filters.value.area_to[store.state.areaUnit]
	}
})

const displayPrice = computed(() => {
	let displayString
	if (price.value.from || price.value.to) {
		displayString = `${
			price.value.from
				? simbol[store.state.activeMoney] + formatCash(+price.value.from || 0)
				: 'Min'
		} — ${
			price.value.to
				? simbol[store.state.activeMoney] + formatCash(+price.value.to || 0)
				: 'Max'
		}`
	} else displayString = null
	return displayString
})

const displayArea = computed(() => {
	let displayString
	if (area.value.from || area.value.to) {
		displayString = `${
			area.value.from ? formatNumber(+area.value.from) : 'Min'
		} — ${area.value.to ? formatNumber(+area.value.to) : 'Max'}`
	} else displayString = null
	return displayString
})

const currentSquareSymbol = computed(() => {
	return squareSymbols[store.state.areaUnit]
})

const types = computed({
	get() {
		const ids = route.query.type_id
		return ids?.split(',') ?? []
	},
	set(ids) {
		let queryString
		if (ids.length === 0) {
			queryString = undefined
		} else {
			queryString = ids.toString()
		}
		mutateQueryState({ type_id: queryString })
	}
})

const displayTypes = computed(() => {
	const t = types.value
	const selectedTypes = filters.value.types.filter((type) =>
		t.includes(type.id.toString())
	)
	return selectedTypes.map((itm) => itm.title).join(', ')
})

const bedrooms = computed({
	get() {
		const bedrooms = route.query.bedroom_count
		return bedrooms?.split(',') ?? []
	},
	set(counts) {
		let queryString
		if (counts.length === 0) {
			queryString = undefined
		} else {
			queryString = counts.toString()
		}
		mutateQueryState({ bedroom_count: queryString })
	}
})

const displayRooms = computed(() => {
	let displayString
	if (bedrooms.value.length === 0) {
		displayString = undefined
	} else {
		displayString = `${
			bedrooms.value.map((bedroom) => {
				if (bedroom.toString() === '0') {
					return t('general.studio')
				} else {
					return bedroom
				}
			}) || 0
		} ${t('general.rooms')};
    `
	}
	return displayString
})

const regions = computed({
	get() {
		const regions = route.query.region
		return regions?.split(',') ?? []
	},
	set(regions) {
		let queryString
		if (regions.length === 0) {
			queryString = undefined
		} else {
			queryString = regions.toString()
		}
		mutateQueryState({ region: queryString })
	}
})

const displayRegions = computed(() => {
	const r = filters.value.region.filter((region) =>
		regions.value.includes(region.id.toString())
	)
	return r.map((itm) => itm.title).join(', ')
})

const cities = computed({
	get() {
		const cities = route.query.city
		return cities?.split(',') ?? []
	},
	set(cities) {
		let queryString
		if (cities.length === 0) {
			queryString = undefined
		} else {
			queryString = cities.toString()
		}
		mutateQueryState({ city: queryString })
	}
})

const displayCities = computed(() => {
	const c = filters.value.city.filter((city) =>
		cities.value.includes(city.id.toString())
	)
	return c.map((itm) => itm.title).join(', ')
})

const developers = computed({
	get() {
		const developers = route.query.bldr
		return developers?.split(',') ?? []
	},
	set(developers) {
		let queryString
		if (developers.length === 0) {
			queryString = undefined
		} else {
			queryString = developers.toString()
		}
		mutateQueryState({ bldr: queryString })
	}
})

const displayDevelopers = computed(() => {
	const r = filters.value.developers.filter((item) =>
		developers.value.includes(item.slug.toString())
	)
	return r.map((itm) => itm.title).join(', ')
})

const projects = computed({
	get() {
		const projects = route.query.pr_slug
		return projects?.split(',') ?? []
	},
	set(regions) {
		let queryString
		if (regions.length === 0) {
			queryString = undefined
		} else {
			queryString = regions.toString()
		}
		mutateQueryState({ pr_slug: queryString })
	}
})

const displayProjects = computed(() => {
	const p = filters.value.projects.filter((project) =>
		projects.value.includes(project.slug.toString())
	)
	return p.map((itm) => itm.title).join(', ')
})

const yearConst = computed({
	get() {
		const yearConst = route.query.year_const
		return yearConst?.split(',') ?? []
	},
	set(yearConst) {
		let queryString
		if (yearConst.length === 0) {
			queryString = undefined
		} else {
			queryString = yearConst.toString()
		}
		mutateQueryState({ year_const: queryString })
	}
})

const displayYears = computed(() => {
	return yearConst.value
		.map(
			(itm) => itm.toString().charAt(0).toUpperCase() + itm.toString().slice(1)
		)
		.join('; ')
})

const hasParkingView = computed(() => {
	const has_parking_route = route.query.has_parking
	if (has_parking_route) {
		return Number(has_parking_route) === 1 ? 'Yes' : 'No'
	}
	return null
})

const hasParking = computed({
	get() {
		return route.query.has_parking ?? null
	},
	set(value) {
		if (value === null) value = undefined
		mutateQueryState({ has_parking: value })
	}
})

const postHandoverView = computed(() => {
	const post_handover_route = route.query.ph_over
	if (post_handover_route) {
		return Number(post_handover_route) === 1 ? 'Yes' : 'No'
	}
	return null
})

const postHandover = computed({
	get() {
		return route.query.ph_over ?? null
	},
	set(value) {
		if (value === null) value = undefined
		mutateQueryState({ ph_over: value })
	}
})

const furnishedView = computed(() => {
	const furnished_route = route.query.furnished
	if (furnished_route) {
		const title = config.furnished.find(
			(itm) => itm.value === furnished_route
		)?.title
		return title ? t(title) : null
	}
	return null
})

const furnished = computed({
	get() {
		return route.query.furnished ?? null
	},
	set(value) {
		if (value === null) value = undefined
		mutateQueryState({ furnished: value })
	}
})

function mutateQueryState(partialQuery) {
	const query = cloneDeep(route.query)
	for (const [key, value] of Object.entries(partialQuery)) {
		query[key] = value
	}

	if (props.type === 'page') {
		router.push({ query: query })
	} else {
		route.query = query
	}
}

function currencyMapper(unit) {
	return unit === 'USD' ? 'dollar' : unit.toLowerCase()
}

function submit() {
	if (props.type === 'page') {
		emit('update')
	} else {
		router.push({
			name: 'Search',
			params: { lang: store.state.uiLanguage },
			query: route.query
		})
	}
}

async function clear() {
	const query = {}
	if (props.type === 'page') {
		await router.push({ query: query })
	} else {
		route.query = query
	}
	emit('update')
}

async function loadFilters() {
	const timestamp = new Date()
	_loadFiltersCall = timestamp
	const response = await createRequestBroker(
		requestBrokerConfigs.GETSearchFilters,
		{
			queryPayload: {
				...route.query
			}
		}
	)

	if (timestamp === _loadFiltersCall) {
		filters.value = response.data.result
	}
}

watch(() => route.query, loadFilters, { deep: true })
watch(
	() => store.state.areaUnit,
	(newUnit, oldUnit) => {
		const unitCoefficients = {
			square_meter: 1,
			square_foot: 10.76
		}
		const changeMetric = (value) =>
			(value / unitCoefficients[oldUnit ?? 'square_meter']) *
			unitCoefficients[newUnit ?? 'square_meter']

		const { from, to } = area.value
		area.value = {
			from: from ? Math.floor(changeMetric(from)) : undefined,
			to: to ? Math.ceil(changeMetric(to)) : undefined
		}
	}
)

watch(
	() => store.state.activeMoney,
	async (newUnit, oldUnit) => {
		const response = await createRequest(requestConfigs.GETPlaceCurrency)
		const currency = response.response
		const changeCurrency = (value) =>
			(value / currency[currencyMapper(oldUnit)]) *
			currency[currencyMapper(newUnit)]
		const { from, to } = price.value
		price.value = {
			from: from ? Math.floor(changeCurrency(from)) : undefined,
			to: to ? Math.ceil(changeCurrency(to)) : undefined
		}
	}
)
</script>

<style lang="scss">
.broker-search-panel {
	border-radius: 4px;
	border: 1px solid var(--color-stroke, #e5e5e5);
	padding: 30px;
	max-width: 1105px;
	margin: 0 auto;

	&__container {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__btns {
		display: flex;
		align-items: center;
		gap: 30px;
		width: 100%;
		button {
			max-width: 210px;
			width: 100%;
		}
	}
	&__body {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
		gap: 10px;
	}
	&__input {
		cursor: pointer;
		&.placeholder-wrap {
			position: relative;
			.main-textarea-content {
				display: flex;
				align-items: center;
				padding-top: 8px;
				resize: none;
				overflow: hidden;
				-webkit-line-clamp: 2;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}
			.main-textarea-content::placeholder,
			.main-input-content::placeholder {
				white-space: pre-line;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				max-width: 55%;
			}
		}
	}
	&__drop-content {
		margin-top: 10px;

		&--project {
		}
	}
	&__area-input {
		& .dropdown__content {
			left: unset;
		}
	}
	@media screen and (max-width: 768px) {
		border-radius: 0;
		border: none;
		padding: 30px 0;
		&__body {
			grid-template-columns: 1fr;
		}
		&__btns {
			flex-direction: column;
			gap: 10px;
			button {
				max-width: 100%;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.advanced-list {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	align-items: center;
	grid-gap: 10px;
	padding: 20px;
	width: 300px;
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-top: 10px;
	@media (max-width: 570px) {
		width: 100%;
	}

	&__list {
		display: flex;
		justify-content: space-between;
		margin-right: 0;
		margin-bottom: 6px;
	}

	&__item {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		label {
			position: relative;
			display: flex;
			align-items: center;
			padding-right: 28px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			text-align: right;
			color: #808080;
			cursor: pointer;

			&:before {
				content: '';
				position: absolute;
				right: 0;
				width: 18px;
				height: 18px;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 18px;
			}

			&.active:before {
				background-color: #c99d56;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: 4px;
				width: 11px;
				margin: auto 0;
				height: 7px;
				background-image: url('~@/modules/search/assets/check.svg');
				background-repeat: no-repeat;
				background-size: 11px 7px;
				z-index: 3;
			}
		}
	}

	&__info {
		flex-basis: 100%;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #808080;
	}
}
</style>
