<template>
	<div class="year-construction">
		<ul class="year-construction__list">
			<li
				v-for="item in list"
				:key="item.id"
				class="year-construction__item result-item"
			>
				<or-checkbox
					style-type="main"
					name="year-construction"
					:checked="checked.includes(item.id.toString())"
					:label="item.title"
					:value="item.id"
					v-on:update:checked="(e) => check(item, e)"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus'

export default {
	props: {
		isHiddenOnClick: {
			type: Boolean,
			default: true
		},
		modelValue: {
			type: Array,
			default: null
		}
	},
	data() {
		return {
			checked: this.modelValue
		}
	},
	// mounted() {
	// 	if (this.defaultValues) {
	// 		this.checked = this.defaultValues.split(',')
	// 	}
	// },
	computed: {
		list() {
			const arr = [{ title: 'Ready', id: 'ready' }]
			// TODO: refactoring
			for (
				let i = new Date().getFullYear();
				i < new Date().getFullYear() + 5;
				i++
			) {
				arr.push({ title: i.toString(), id: i })
			}

			return arr
		}
	},
	methods: {
		select(payload) {
			this.$emit('select', payload)

			// Закрываем дроп даун
			if (this.isHiddenOnClick) {
				eventBus.trigger('dropdown-hide')
			}
		},
		check(item, value) {
			const newValue = [...this.modelValue]
			const idx = newValue.findIndex(
				(propertyId) => propertyId === item.id.toString()
			)

			if (idx === -1) {
				newValue.push(item.id.toString())
			} else {
				newValue.splice(idx, 1)
			}

			this.$emit('update:modelValue', newValue)
		}
	}
}
</script>

<style lang="scss">
.year-construction {
	min-width: 250px;
	padding: 30px;
	background: #ffffff;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-top: 10px;
	&__item {
		display: flex;
		justify-content: space-between;
		max-width: 100% !important;
	}
}
</style>
