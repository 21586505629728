<template>
	<div class="broker-search">
		<div class="container">
			<transition name="fade">
				<div class="broker-search__header">
					<div class="broker-search__header-left">
						<button
							:class="{ active: isShowMobileMap === false }"
							class="btn"
							@click="this.isShowMobileMap = false"
						>
							List
						</button>
						<button
							:class="{ active: isShowMobileMap === true }"
							class="btn"
							@click="this.isShowMobileMap = true"
						>
							Map
						</button>
					</div>
					<div class="broker-search__header-right">
						<!-- <router-link
							class="broker-search__header-create btn"
							:to="{
								name: 'CreateOffer',
								params: { lang: $store.state.uiLanguage }
							}"
						>
							Create offer
						</router-link> -->
						<!-- <router-link
							class="broker-search__header-home"
							:to="{
								name: 'MainRoute',
								params: { lang: $store.state.uiLanguage }
							}"
						>
							<HomeIcon />
						</router-link> -->
					</div>
				</div>
			</transition>
			<transition name="slide-fade" mode="out-in">
				<suspense>
					<BrokerSearchPanel
						v-if="isShowParameters"
						is-show-clear-btn
						type="page"
						@update="get(), getPoints()"
						class="search-panel-search broker-search__search-panel"
					/>
				</suspense>
			</transition>
			<transition name="fade" mode="out-in">
				<div class="broker-search__count-sort">
					<p class="broker-search__result-count">
						{{ meta.total }}
						<span>
							{{ $t('general.results') }}
						</span>
					</p>

					<!-- <SortPanel @update="get" /> -->
				</div>
			</transition>

			<transition name="fade" mode="out-in">
				<div v-if="loading && isSkeleton" class="broker-search__content">
					<or-skeleton style="height: 700px" class="broker-search__map-box" />
					<div v-for="item in 8" :key="item" class="broker-search__skeleton">
						<or-skeleton
							style="margin-bottom: 15px"
							class="broker-search__skeleton--img"
						/>
						<or-skeleton
							style="height: 19px; margin-bottom: 5px; max-width: 200px"
						/>
						<or-skeleton style="height: 18px; margin-bottom: 4px" />
						<or-skeleton
							style="height: 34px; margin-bottom: 15px; max-width: 100px"
						/>
						<or-skeleton style="height: 21px; margin-bottom: 10px" />
						<or-skeleton style="height: 21px; margin-bottom: 15px" />
						<or-skeleton style="height: 16px; max-width: 100px" />
					</div>
				</div>
				<div
					v-else
					class="broker-search__content"
					:class="{ 'mobile-map': isShowMobileMap }"
				>
					<!-- TODO: сменить обновление карты -->
					<div
						class="broker-search__map-box broker-search-mapbox"
						:key="isShowMobileMap"
					>
						<MapBox
							:coords="coords"
							@updateByDrag="getDataByMap"
							@updateByClick="getDataByMapClick"
							style="height: 700px"
							:key="coords.length"
							:nav-panel="true"
						/>
					</div>
					<MainCard
						v-for="item in list"
						:key="item.id"
						:content="item"
						class="broker-search__item broker-search-list"
						@click="redirect($event, item)"
					/>

					<button
						type="button"
						@click="toggleShowParameters"
						class="btn broker-search__mobile-map-btn"
						aria-label="Location"
					>
						<Parameters /> Parameters
					</button>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import BrokerSearchPanel from '@/components/SearchPanel/BrokerSearchPanel.vue'
import SortPanel from '@/components/SortPanel/index.vue'
import MapBox from '@/components/MapBox.vue'
import MainCard from '@/components/cards/Project.vue'
import Parameters from '@/components/svg/Parameters.vue'
import HomeIcon from '@/components/svg/HomeIcon.vue'
import debounce from 'lodash/debounce'
import { createRequestBroker } from '@/api/requestBuilder'
import requestBrokerConfigs from '@/api/requestBrokerConfigs'

export default {
	components: {
		BrokerSearchPanel,
		SortPanel,
		Parameters,
		HomeIcon,
		MainCard,
		MapBox
	},
	data() {
		return {
			config: {
				_payloadFields: [
					'region',
					'type_id',
					'price_from',
					'price_to',
					'bedroom_count',
					'status',
					'furnished',
					'area_from',
					'order_by',
					'pr_slug',
					'direction',
					'area_to',
          'city',
					'squ',
					'has_parking',
					'ph_over',
					'bldr',
					// 'developer',
					'year_const'
				]
			},
			isShowMobileMap: false,
			isShowParameters: false,
			loading: true,
			scrollElement: null,
			coords: [],
			list: [],
			meta: {},
			isSkeleton: true
		}
	},
	async beforeMount() {
		await this.get()
		await this.getPoints()
	},
	mounted() {
		this.scrollElement = document.getElementsByTagName('html')[0]

		this.handleDebouncedScroll = debounce(this.handleScroll, 100)
		window.addEventListener('scroll', this.handleDebouncedScroll, {
			passive: true
		})
		if (window.innerWidth > 768) {
			this.isShowParameters = true
		} else return
	},
	onUnmounted() {
		window.removeEventListener('scroll', this.handleDebouncedScroll)
	},
	computed: {
		payloads() {
			let payload = {}

			this.config._payloadFields.forEach((itm) => {
				if (this.$route.query[itm]) {
					payload[itm] = this.$route.query[itm]
				}
			})

			// Регионы
			if (payload.region) {
				try {
					const regions = JSON.parse(payload.region)
					payload.region = regions.map((itm) => itm.id).join(',')
				} catch (e) {}
			}

			// bedroom_count
			if (payload.bedroom_count) {
				payload.bedroom_count = payload.bedroom_count.replace('Studio', '0')
			}

			// Проверить, чтобы не отпрвлялся пустой массив
			if (!payload.status?.length) {
				delete payload.status
			}

			return payload
		}
	},
	methods: {
		toggleShowParameters() {
			if (this.isShowParameters) {
				this.$store.commit('hideModal')
				this.$refs.container?.scrollIntoView({ behavior: 'smooth' })
			} else if (!this.isShowParameters) {
				this.$refs.container?.scrollIntoView({ behavior: 'smooth' })
			}
			this.isShowParameters = !this.isShowParameters
		},
		getDataByMap({ slugs, points }) {
			this.get(null, slugs, points)
		},
		async getDataByMapClick({ slugs, points }) {
			if (window.screen.width < 768) {
				const payload = {
					...this.payloads,
					per_page: 24,
					page: 1
				}

				if (slugs && slugs.length) {
					payload.slugs = slugs.join(',')
				}

				if (points) {
					payload.points = points
				}

				// TODO
				this.get(null, slugs, points).finally(() => {
					this.$store.commit('showModal', {
						name: 'SearchModal',
						props: {
							isBroker: true,
							list: [...this.list],
							meta: { ...this.meta },
							payload: {
								...payload
							}
						}
					})
				})
			} else {
				await this.get(false, slugs, points)
			}
		},
		async handleScroll() {
			if (
				this.scrollElement?.scrollTop + this.scrollElement?.clientHeight >=
				this.scrollElement?.scrollHeight - 600
			) {
				if (this.loading) {
					return
				}

				// // Проверяем остались ли посты
				if (this.meta.current_page < this.meta.last_page) {
					await this.get(true)
				}
			}
		},
		redirect(event, project) {
			if (event.target.type !== 'button') {
				this.$router.push({
					name: 'BrokerProject',
					query: {
						...this.$route.query
					},
					params: {
						slug: project.slug,
						lang: this.$store.state.uiLanguage
					}
				})
			}
		},
		async get(more, slugs, points) {
			this.loading = true

			const payload = {
				...this.payloads,
				per_page: 24,
				page: more ? (this.meta.current_page || 0) + 1 : 1
			}

			if (slugs && slugs.length) {
				payload.pr_slug = slugs.join(',')
			}

			if (points) {
				payload.points = points
			}

			createRequestBroker(requestBrokerConfigs.GETSearchProjects, {
				queryPayload: {
					...payload
				}
			})
				.then((result) => {
					if (!more) {
						this.list.length = 0
					}

					const { items, meta } = result.data
					this.list.push(...items)
					this.meta = meta
				})
				.finally(() => {
					this.loading = false
					this.isSkeleton = false
				})
		},
		async getPoints() {
			createRequestBroker(requestBrokerConfigs.GETSearchCoords, {
				queryPayload: {
					...this.payloads
				}
			}).then(({ data }) => {
				this.coords = [...data.items]
			})
		}
	}
}
</script>

<style lang="scss">
.broker-search {
	&__header {
		padding: 0 20px 10px;
		align-items: center;
		justify-content: space-between;
		display: none;
		border-bottom: 1px solid var(--opacity-black-10, rgba(0, 0, 0, 0.1));
		margin: 0 -20px 0;
		@media screen and (max-width: 768px) {
			display: flex;
			padding-top: 10px;
		}
	}
	&__header-left {
		display: flex;
		align-items: center;
		border-radius: 4px;
		border: 1px solid var(--opacity-black-10, rgba(0, 0, 0, 0.1));
		background: var(--background-1, #fff);
		& > button {
			padding: 4px 25.5px;
			color: var(--primary-1, #c99d56);
			font-size: 12px;
			line-height: normal;
			border-radius: 4px;
			overflow: hidden;
			transition: all 0.2s ease;
			&.active {
				background: var(--primary-1, #c99d56);
				color: white;
			}
		}
	}
	&__header-right {
		display: flex;
		gap: 10px;
		align-items: center;
	}
	&__header-create {
		border-radius: 4px;
		color: var(--text-1, #000);
		border: 1px solid rgba(201, 157, 86, 0.5);
		padding: 5px 10px;
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
	}
	&__header-home {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background: #c99d56;
		border-radius: 50%;
	}

	&__search-panel {
		margin-bottom: 30px;
	}

	&__count-sort {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		padding-top: 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	&__result-count {
		display: inline-flex;
		justify-content: center;
		flex-wrap: wrap;
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 33px;
		color: #000000;
		text-align: center;
		flex-direction: column;

		span {
			//flex-basis: 100%;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			color: #808080;
		}
	}

	&__mobile-map-btn {
		display: none;
		position: fixed;
		bottom: 70px;
		right: 20px;
		padding: 10px 20px;
		font-size: 16px;
		line-height: 1;
		align-items: center;
		gap: 10px;
		color: white;
		border-radius: 100px;
		background: var(--primary-1, #c99d56);
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		z-index: 9999;
		@media (max-width: 768px) {
			display: flex;
		}
	}

	&__map-box {
		width: 100% !important;
		margin-right: 10px;
		grid-column: 1 / 3;
		grid-row: 1 / 3;

		@media (max-width: 900px) {
			grid-column: 1 / 4;
			grid-row: 1 / 1;
		}

		@media (max-width: 700px) {
			grid-column: 1 / 3;
		}

		@media (max-width: 500px) {
			grid-column: 1 / 1;
			margin-bottom: 20px;
		}
	}

	&__content {
		position: relative;
		padding-bottom: 110px;
		display: grid;
		gap: 10px;
		grid-template-columns: repeat(4, 1fr);
		.estate-card__img-wrapper {
			img {
				max-width: 280px;
				width: 100%;
			}
			.skeleton-basic {
				width: 100%;
				height: unset;
			}
		}

		@media (max-width: 900px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (max-width: 768px) {
			padding-bottom: 45px;
		}

		@media (max-width: 700px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: 500px) {
			display: block;
			width: 100%;
			grid-template-columns: unset;
			.estate-card__img-wrapper {
				img {
					max-width: unset;
				}
				//.skeleton-basic {
				//	width: 100% !important;
				//	height: unset !important;
				//	aspect-ratio: 16 / 10;
				//}
			}
		}

		@media (max-width: 768px) {
			.broker-search-mapbox {
				display: none;
			}

			&.mobile-map {
				.broker-search-mapbox {
					display: block;
				}

				.broker-search-list {
					display: none;
				}
			}
		}
	}

	&__item {
		margin-bottom: 30px;
	}

	&__skeleton {
		max-width: 280px;
		&--img {
			height: 130px;
		}
		@media (max-width: 500px) {
			max-width: 100%;
			&--img {
				height: auto;
				padding-bottom: 67%;
			}
		}
	}

	&__skeleton-panel {
		width: 100%;
		height: 106px;
		@media (max-width: 1180px) {
			height: 529px;
		}
		@media (max-width: 768px) {
			height: 549px;
		}
		@media (max-width: 550px) {
			height: 615px;
		}
	}

	.slide-fade-enter-active {
		transition: all 0.2s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.15s ease-out;
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateY(-20px);
		opacity: 0;
	}
}
</style>
